import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"

const WritingPage = () => {
  return (
    <Layout>
    <div>
      <AniLink className="u-link" fade duration={1} to="/quora">Quora Answers</AniLink> 
    </div>
    <div>
      <AniLink className="u-link" fade duration={1} to="/dreaming-sonder">Dreaming Sonder</AniLink> 
    </div>
    <div>
      <AniLink className="u-link" fade duration={1} to="/hot-soup-restaurant">Hot Soup Restaurant</AniLink> 
    </div>
    <div>
      <AniLink className="u-link" fade duration={1} to="/medium">Medium</AniLink> 
    </div>
    </Layout>
  )
}

export default WritingPage
